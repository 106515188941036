<template>
<div class="guide-video">
  <a-modal v-model="visible" :footer="false" width="60%" wrapClassName="guide-video" :afterClose="closemodel" :closable="false" :centered="true">

  <video class="fullscreenvideo" id="video1" autoplay muted loop preload controls="false" width="100%"
         webkit-playsinline="true" x-webkit-airplay="allow" playsinline="" x5-video-player-type="h5"
         x5-video-orientation="portrait" x5-video-player-fullscreen="portrait">
    <!--<source src="../../../assets/video/video1.mp4">-->
    <source v-if="videolist.wl7" :src="'https://adameve.infura-ipfs.io/ipfs/'+this.videolist.wl7">
  </video>
  </a-modal>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "guide-video",
  props: {
    setShow: Function,
  },
  computed: {
    ...mapGetters(["videolist",])
  },
  data(){
    return{
      visible: true,
    }
  },
  created() {
    },
  methods:{
    closemodel() {
      this.visible = false

      if (this.setShow) {
        this.setShow(false);
      }

    }

  }
}
</script>

<style scoped lang="scss">
::v-deep .ant-modal-content {
  background: transparent;
  //border-radius: 36px;
}
.guide-video{
  position: relative;


  .guide-video{

    .fullscreenvideo{
      position: absolute;
      width: 80%;

    }

  }

}

</style>
