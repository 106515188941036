/**
 * 判断是否为空
 */
export async function mGet(path){
    return fetch(path, {
        method: "get",
        headers: {
            "Content-type": "application/json",
        },
    }).then((response) => response.json());
}


