<template>
  <div class="guide">
    <div class="d1" @click="adamapp()">
      <span class="a1">
        <span class="iconfont" style="font-size: 36px;color: white"> &#xe672;</span>
       </span>
      <div class="a2">ENTER ADAM&EVE</div>
    </div>
    <div class="d2" @click="Apply()">
    <span>
      APPLY WHITLIST
    </span>
    </div>
    <div class="d3" @click="Clickvideo()">
      <span class="iconfont" style="color: #757575">&#xe66d;</span>
      <div class="c2">PLAY VIDEO</div>
    </div>
    <guidevideos v-if="ShowVideo" :setShow="setShowModal"></guidevideos>

  </div>
</template>

<script>
import guidevideos from "./components/guide-video";
import {addRecord} from "../../SDK/record";
import {mGet} from "../../utils/tools";

export default {
  name: "index",
  components: {guidevideos},
  data() {
    return {
      c:3,
      ShowVideo: false
    }
  },
  async beforeCreate(){
    let vlist = await mGet("https://cloudflare-ipfs.com/ipns/config.adameve.app?v="+Date.now());
    this.$store.dispatch("user/setVideos",vlist)

  },
  methods: {
    adamapp(){
      window.open("https://www.adameve.app/#/home")
    },
    Apply() {
      addRecord((code, message) => {
        console.log(code, message);
        if (code==2) {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: "<div style='border-radius: 26px; padding:26px 41px;display: flex;justify-content: center;align-items: center;background: linear-gradient(90deg, #BF77AE 0%, #5461BB 87%, #8D46BD 100%)'><div class='iconfont' style='color: white;font-size: 59px'>&#xe8ad;</div><div style='font-size: 35px;color: white;margin-left: 40px'>APPLY SUCCESSFULLY</div></div>",
            showClose: false,
          });
        }else if(code==3){
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: "<div style='border-radius: 26px; padding: 23px 22px 26px 4px;display: flex;justify-content: center;align-items: center;background: linear-gradient(90deg, #C9BD65 0%, #C48254 65%, #C45369 100%)'><div class='iconfont' style='color: white;font-size: 59px'>&#xe8b8;</div><div style='font-size: 35px;color: white;margin-left: 40px'>APPLY UNSUCCESSFULLY</div></div>",
            showClose: false,
          });
        }else if(code==1){
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: "<div style='border-radius: 26px; padding:26px 41px;display: flex;justify-content: center;align-items: center;background: linear-gradient(90deg, #BF77AE 0%, #5461BB 87%, #8D46BD 100%)'><div class='iconfont' style='color: white;font-size: 59px'>&#xe8ad;</div><div style='font-size: 35px;color: white;margin-left: 40px'>APPLYED</div></div>",
            showClose: false,
          });
        }
      });
    },
    Clickvideo() {
      this.ShowVideo = !this.ShowVideo
    },
    setShowModal(value) {
      this.ShowVideo = value;
    },
  }
}
</script>

<style scoped lang="scss">


.guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1080px;
  width: 100%;
  background-image: url("../../assets/img/aProMax.png");
  background-color: #090909;
  background-repeat: no-repeat;
  //background-size:100% auto;
  background-size: cover;
  background-position: center center;
  justify-content: flex-end;
  padding-left: 3.0%;

  .d1 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 496px;
    height: 56px;
    background: linear-gradient(180deg, #000000 0%, #27282A 40%, rgba(38, 43, 51, 0.6100) 100%);
    border-radius: 32px;
    //border: 1px solid;
    border-image: linear-gradient(180deg, rgba(41, 40, 40, 1), rgba(63, 63, 65, 1), rgba(67, 72, 81, 0.38)) 1 1;

    .a1 {
      margin-right: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 39px;
      height: 39px;
      background: linear-gradient(180deg, #BF77AE91 0%, #465EBD91 100%);

    }

    .a2 {
      font-size: 36px;
      font-family: Futura-Medium, Futura;
      font-weight: 500;
      color: #EDEDED;
      line-height: 47px;
      letter-spacing: 4px;
    }
  }

  .d2 {
    cursor: pointer;
    margin: 18px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 282px;
    height: 26px;
    background: linear-gradient(90deg, #BF77AECC 0%, #465EBDCC 100%);
    border-radius: 13px;

    span {
      font-size: 18px;
      font-family: Futura-Medium, Futura;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 25px;
      letter-spacing: 4px;


    }
  }
  .d2:hover {
    cursor: pointer;
    margin: 18px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 282px;
    height: 26px;
    background: linear-gradient(90deg, #BF77AE 0%, #465EBD 100%);
    border-radius: 13px;

    span {
      font-size: 18px;
      font-family: Futura-Medium, Futura;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 25px;
      letter-spacing: 4px;


    }
  }

  .d3 {
    cursor: pointer;
    margin-bottom: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 16px;
    background: #363636A6;
    border-radius: 8px;



    .c2 {
      font-size: 12px;
      font-family: Futura-Medium, Futura;
      font-weight: 500;
      color: #757575;
      line-height: 15px;
      letter-spacing: 2px;

    }
  }

}
</style>
